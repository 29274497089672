/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setGame } from "../../services/store"
import Shine from "../../components/Shine"
import Flags from "../../components/Flags"
import { loadImages, pushRouter, toDisplayPrice } from "../../utility/helpers"
import "./FeaturedCard.css"

export default function FeaturedCard({ data }) {
  const dispatch = useDispatch()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (!data) { return }
    loadImages(data.bg_image, data.title_image).then(() => {
      setReady(true)
    })
  }, [data])
  return (
    <div
      className="featured-card"
      data-ready={ready}>
      {
        data && (
          <div
            className="featured-card-inner"
            style={{
              backgroundImage: `url(${data.title_image}), url(${data.bg_image})`
            }}>
            <Flags data={data} />
            <Shine
              visible={ready && data.winnerCount > 0}
              color={data.paidGame ? "gold" : "white"}
            />
            <div className="featured-card-content">
              <div className="featured-card-content-details">
                <div className="featured-card-title">
                  {data.name}
                </div>
                <div className="featured-card-details">
                  {
                    data.winnerCount > 0
                      ? `${data.winnerCount} Winners • `
                      : ""
                  }
                  {
                    data.paidGame
                      ? toDisplayPrice(data)
                      : "FREE"
                  }
                </div>
              </div>
              <div className="featured-card-content-buttons">
                <button
                  className="featured-card-button"
                  onClick={() => {
                    dispatch(setGame(data))
                    pushRouter("view")
                  }}>
                  Play
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
